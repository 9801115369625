'use strict';


var deps = ['header.controller',
  'spinner.controller',
  'database.controller',
  'document.controller',
  'server.controller',
  'vertex.controller',
  'aside.controller',
  'notification.controller',
  'mgcrea.ngStrap',
  'ui.codemirror',
  'LocalStorageModule',
  'aside.services',
  'graph.services',
  'icon.services',
  'bootstrap.tabset',
  'ngTable',
  'filters',
  'rendering',
  'graph',
  'duScroll',
  'ui.select',
  'ngRoute',
  'ngAnimate',
  'ngSanitize',
  'angularSpectrumColorpicker',
  'pascalprecht.translate',
  'ngTagsInput',
  'frapontillo.bootstrap-switch',
  'datatables',
  'smart-table'];


var App = angular.module('SSOCircleIDGraphApp', deps);

App.config(function ($routeProvider, $httpProvider, $translateProvider, $translatePartialLoaderProvider) {
  $routeProvider
    .when('/database/:database/graph', {
      templateUrl: 'views/database/graph/graph.html',
      controller: 'GraphController',
      resolve: DatabaseResolve
    })
    .when('/database/:database/graph/:q', {
      templateUrl: 'views/database/graph/graph.html',
      controller: 'GraphController',
      resolve: DatabaseResolve
    })
    .when('/404', {
      templateUrl: 'views/404.html'
    })
    .otherwise({
      redirectTo: '/database/IdentityGraph/graph'
    });

  $translateProvider.useLoader('$translatePartialLoader', {
    urlTemplate: 'translations/{lang}/{part}.json'
  });

  $translatePartialLoaderProvider.addPart('hint');

  $translateProvider.preferredLanguage('en-US');
  $httpProvider.interceptors.push(function ($q, Notification, $rootScope) {
    return {
      responseError: function (rejection) {


        if (rejection.status == 0) {
          Notification.clear();
          $rootScope.$broadcast("server:down");

        } else if (rejection.status == 403) {
          console.log(rejection.data);
          Notification.push({content: rejection.data, error: true, autoHide: false});
        } else if (rejection.status == 401 && checkError401(rejection.data)) {
          console.log(rejection.data);
          window.location = "https://idp.ssocircle.com/sso/UI/Login?goto=https%3A%2F%2Fidp.ssocircle.com%2Fapp%2F%23%2Fdatabase%2FIdentityGraph%2Fgraph";
        
        //  Notification.push({content: rejection.data, error: true, autoHide: false});
        }
        return $q.reject(rejection);
      }
    };
  });

});
var POLLING = 5000;
App.run(function ($rootScope, $interval, DatabaseApi, Notification, Spinner, $templateCache, Aside) {
  $rootScope.$on('$routeChangeSuccess', function (event, currentRoute, oldRoute) {
    switch (currentRoute.templateUrl) {
      case 'views/login.html':
        $rootScope.bodyClass = 'landing-page';
        break;
      default:
        $rootScope.bodyClass = 'normal-page';
        break;
    }
    if (currentRoute.$$route.controller === 'ServerDashboardController') {
      $rootScope.$emit("servermgmt:open");
    } else {
      $rootScope.$emit("servermgmt:close");
    }
    if (oldRoute && currentRoute.originalPath != oldRoute.originalPath) {
      Notification.clear();
    }
    NProgress.done();
  });
  $rootScope.$on("$routeChangeStart", function (event, next, current) {
    Aside.destroy();
    NProgress.start();
    NProgress.set(0.2);
    NProgress.set(0.4);

  })

  $interval(function () {
    DatabaseApi.listDatabases().then(function (data) {
      $rootScope.$broadcast("server:up");
    }, function error(data) {
      $rootScope.$broadcast("server:down");
    })
  }, 60000);
//Hosx set to 10 min
  $templateCache.put('popover/popover.tpl.html', '<div class="popover"><div class="arrow"></div><h3 class="popover-title" ng-bind="title" ng-show="title"></h3><div class="popover-content" ng-bind-html="content"></div></div>');
})

var checkError401 = function (data) {

  var valid = true;
  if (typeof  data == 'string') {
    valid = data != "Logged out";
  } else {
    valid = data.fault.message == "Missing Credentials" || data.fault.description.indexOf("SSOToken invalid") != -1;
   //     valid = data.errors[0] || data.errors[0].content != "Logged out"
  }
  return valid;

}
$('body').on('keyup', function (e) {

  if (e.keyCode == 27) {
    $('.modal-backdrop').click()
  }
})
