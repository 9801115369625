var aside = angular.module('graph.services', []);

aside.factory('Graph', function () {

    var data = [];
    return {
        query: "",
        data: data,
        add: function (d) {
            this.data = this.data.concat(d);
        },
        remove: function (d) {
            this.params.cls = "";
        }
    }
});

aside.factory('GraphConfig', function ($resource, localStorageService, DocumentApi, $http, $q, Database) {

    var resource = $resource(API + 'database/:database');
    var CLAZZ = "idgraph"
    var TYPE = "GraphConfig"

    resource.CLAZZ = CLAZZ;

    resource.init = function () {
        var database = Database.getName();


        var deferred = $q.defer();
        var text = API + 'command/' + database + '/sql/-/20?format=rid,type,version,class,graph';
        var query = "CREATE CLASS {{clazz}}";
        query = S(query).template({clazz: CLAZZ}).s;
        $http.post(text, query).success(function (data) {
            Database.refreshMetadata(database, function () {
              deferred.resolve(data);
            });


        });
        return deferred.promise;
    }
    resource.get = function () {

        var database = Database.getName();
        var username = Database.currentUser();

        var deferred = $q.defer();
        // HOSx
        //var text = API + 'command/' + database + '/sql/-/-1?format=rid,type,version,class,graph';
        var text = API + 'command/' + database + '/sql/-/-1';
        // var query = "{ \"command\" : \"select * from {{clazz}} where user.name = '{{username}}' and type = '{{type}}'\"}";
        // HOSx use API
        var query = "{ \"command\" : \"GetGraphConfig/{{username}}\"}";
        query = S(query).template({clazz: CLAZZ, username: username, type: TYPE}).s;
        $http.post(text, query).success(function (data) {
            deferred.resolve(data.result[0]);
        });
        return deferred.promise;
    }
    resource.set = function (config) {
        var deferred = $q.defer();
        var database = Database.getName();
        var username = Database.currentUser();

        if (DocumentApi.isNew(config)) {

            config.type = TYPE;
            var text = API + 'command/' + database + '/sql/-/-1?format=rid,type,version,class,graph';
            var query = "select * from OUser where name = '{{username}}'";
            query = S(query).template({username: username}).s;
            $http.post(text, query).success(function (data) {
                config.user = data.result[0];
                DocumentApi.createDocument(database, config['@rid'], config, function (data) {
                    deferred.resolve(data);
                });
            });

        }
        else {
            DocumentApi.updateDocument(database, config['@rid'], config, function (data) {
                deferred.resolve(data);
            });
        }
        return deferred.promise;
    }
    return resource;
});
